$primary-font: 'Josefin Sans',
sans-serif;

$primary-color: #03A696;
$font-color: #141c3a;
$white: #fff;

// Small tablets and large smartphones (landscape view)
$screen-sm-max: 576px;

// Small tablets (portrait view)
$screen-md-max: 768px;

// Tablets and small desktops
$screen-lg-max: 992px;

// Large tablets and desktops
$screen-xl-max: 1200px;

// Small devices
@mixin sm {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}

// Medium devices
@mixin md {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}

// Large devices
@mixin lg {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
}

// Extra large devices
@mixin xl {
    @media (max-width: #{$screen-xl-max}) {
        @content;
    }
}

// Custom devices
@mixin rwd($screen) {
    @media (max-width: $screen+'px') {
        @content;
    }
}

@for $i from 1 through 9 {
    $weight: $i * 100;

    .text-weight--#{$weight} {
        font-weight: #{$weight};
    }

    .border-left--#{$i} {
        border-left: #{$i}px solid #E6ECF8;
        @include md {
            border-left: 0;
        }
    }

    .border-right--#{$i} {
        border-right: #{$i}px solid #E6ECF8;
        @include md {
            border-right: 0;
        }
    }
}

.rounded-xl {
    border-radius: 1rem !important;
}