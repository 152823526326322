* {
    font-family: $primary-font;
}

a,
img,
.btn {
    outline: none;
}

p {
    font-size: 1.2em;
}

section.first {
    margin-top: 88px;
}

.white {
    color: $white;
}

body.Contact {
    background-image: url(../images/man-code.svg);
    background-repeat: no-repeat;
    background-position: right 100%;
    background-size: 25%;

    @include md {
        background-image: none;
    }
}

.App {
    header {
        .navbar {
            padding: 1.2rem 0;

            @include md {
                padding: 1.2rem 15px;
            }

            .container {
                position: relative;
            }

            .navbar-brand {
                .logo {
                    width: 60px;
                }
            }

            .navbar-toggler {
                outline: none;
            }

            .navbar-collapse {
                a {
                    font-size: 20px;
                    font-weight: 300;
                    color: $font-color;
                    line-height: 1.5;
                    padding: 0.5rem 0.75rem;

                    &.btn-outline-primary {
                        color: $primary-color;
                        border-color: $primary-color;
                        font-weight: 500;
                        padding: 4px 10px 2px 10px;
                        border-width: 2px;
                        margin-left: 10px;

                        &:focus {
                            outline: none;
                        }

                        &:hover {
                            color: #fff;
                            background-color: #03A696;
                        }

                        @include lg {
                            margin-left: 0;
                        }
                    }
                }

                @include lg {
                    &.show {
                        visibility: visible;
                        opacity: 1;
                    }

                    display: flex;
                    background: #fff;
                    position: absolute;
                    right: 0;
                    top: 45px;
                    flex-direction: column;
                    flex-basis: auto;
                    flex-grow: unset;
                    padding: 20px 30px;
                    box-shadow: 0 0.8px 1.2px rgba(0, 0, 0, 0.034),
                    0 2.4px 3px rgba(0, 0, 0, 0.049),
                    0 5.7px 6px rgba(0, 0, 0, 0.061),
                    0 12.4px 11.6px rgba(0, 0, 0, 0.076),
                    0 33px 28px rgba(0, 0, 0, 0.11);
                    border-radius: 10px;
                    visibility: hidden;
                    opacity: 0;
                    transition: all 0.3s ease-in-out;
                }
            }
        }
    }

    .container {
        &.home {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;

            .top-buffer {
                padding-top: 85px;
                display: flex;
                flex-direction: column;
                .avatar {
                    border-radius: 50%;
                    position: relative;
                    overflow: hidden;
                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(180deg, rgba(242,75,95,0) 0%, rgba(1,48,64,0.6) 100%);
                    }
                    .profile {
                        border-radius: 50%;
                    }
                }
                a.btn-outline-primary {
                    color: $primary-color;
                    border-color: $primary-color;
                    font-size: 20px;
                    font-weight: 500;
                    border-width: 2px;
                    padding: 8px 20px 4px 20px;

                    &:focus {
                        outline: none;
                    }

                    &:hover {
                        color: #fff;
                        background-color: #03A696;
                    }
                }

            }
        }
    }

    .section-about {
        background-color: $primary-color;
        padding: 9rem 1.5rem;
        padding-bottom: 18rem;

        @include md {
            padding-top: 6rem;
        }
    }

    .section-skills {
        margin-top: -14rem;
        margin-bottom: 80px;

        .box.card {
            padding: 0 20px;

            .row {
                .col {
                    padding: 15px 30px;

                    @include lg {
                        padding: 15px;

                        &:nth-child(2) {
                            border-right: 0;
                        }
                    }

                    img {
                        width: 58px;
                    }

                    p {
                        text-align: center;
                    }

                    .list-title {
                        color: $primary-color;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .section-portfolio {
        margin-top: 150px;

        .container {
            max-width: 1440px;
        }

        /* fluid 5 columns */
        .grid-sizer {
            width: calc((100% - 120px)/3);

            @include xl {
                width: calc((100% - 60px)/2);
            }

            @include md {
                width: 100%;
            }
        }

        .grid-item {
            width: calc((100% - 120px)/3);
            margin-bottom: 60px;
            position: relative;
            float: left;
            border-radius: 10px;
            overflow: hidden;
            box-shadow:
                0 0.8px 1.2px rgba(0, 0, 0, 0.034),
                0 2.4px 3px rgba(0, 0, 0, 0.049),
                0 5.7px 6px rgba(0, 0, 0, 0.061),
                0 12.4px 11.6px rgba(0, 0, 0, 0.076),
                0 33px 28px rgba(0, 0, 0, 0.11);
            transition: all 0.3s ease-in-out;

            @include xl {
                width: calc((100% - 60px)/2);
            }

            @include md {
                width: 100%;
                margin-bottom: 40px;
            }

            &:hover {
                box-shadow:
                    0 0.8px 1.2px rgba(0, 0, 0, 0.012),
                    0 2.4px 3px rgba(0, 0, 0, 0.018),
                    0 5.7px 6px rgba(0, 0, 0, 0.022),
                    0 12.4px 11.6px rgba(0, 0, 0, 0.028),
                    0 33px 28px rgba(0, 0, 0, 0.04);
                transform: scale(1.02);

                .info-layer {
                    opacity: 1;
                    transform: translate(0, 0);
                }
            }

            img {
                display: block;
                max-width: 100%;
            }

            &.grid-item--width2 {
                width: calc(66.66% - 20px);

                //height: 503px;
                @include xl {
                    width: calc((100% - 60px)/2);
                    //height: auto;
                }

                @include md {
                    width: 100%;
                }
            }

            .info-layer {
                position: absolute;
                bottom: 0;
                width: 100%;
                background: rgba(0, 0, 0, 0.8);
                padding: 12px;
                transform: translate(0, 100%);
                transition: transform .5s ease-in-out;

                h4,
                a {
                    text-align: center;
                    width: 100%;
                    display: block;
                }

                h4 {
                    color: #fff;
                }
            }
        }
    }

    .section-contact {
        margin-top: 150px;

        .popup {
            visibility: hidden;
            -webkit-transition: visibility 0ms linear 0.3s;
            transition: visibility 0ms linear 0.3s;

            &--visible {
                visibility: visible;
                transition: visibility 0ms;

                .popup__background {
                    opacity: 1;
                }

                .popup__content {
                    animation: show-popup 0.3s forwards;

                    &:before {
                        animation: show-icon-cirlce 0.3s forwards 0.15s;
                    }

                    &:after {
                        animation: show-icon 0.3s forwards 0.3s;
                    }
                }
            }

            &__background {
                position: fixed;
                top: 0;
                left: 0;
                z-index: 10000;
                height: 100%;
                width: 100%;
                background: rgba(0, 0, 0, 0.7);
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
            }

            &__content {
                position: fixed;
                top: 50%;
                left: 50%;
                z-index: 10001;
                min-width: 400px;
                padding: 25px 50px;
                padding-top: 130px;
                background: #fff;
                border: 1px solid #ddd;
                border-radius: 6px;
                text-align: center;
                animation: hide-popup 0.3s forwards;

                &:before,
                &:after {
                    position: absolute;
                    top: 25px;
                    left: 50%;
                    -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
                    display: block;
                    height: 90px;
                    width: 90px;
                }

                &:before {
                    content: '';
                    border: 3px solid currentColor;
                    border-radius: 50%;
                    -webkit-transform: translateX(-50%) scale(1, 0);
                    transform: translateX(-50%) scale(1, 0);
                    opacity: 0;
                }

                &:after {
                    content: '\2713';
                    line-height: 100px;
                    font-size: 45px;
                    -webkit-transform: translateX(-50%) scale(0);
                    transform: translateX(-50%) scale(0);
                    opacity: 0;
                }

                &__title {
                    margin-bottom: 14px;
                    font-size: 1.2rem;
                    color: rgba(41, 51, 71, 0.65);
                }

                .button {
                    margin-bottom: 4px;
                    padding: 6px 25px;
                    border: 1px solid $primary-color;
                    border-radius: 4px;
                    font-size: 16px;
                    background: $primary-color;
                    cursor: pointer;
                    font-family: inherit;
                    text-transform: uppercase;
                    color: #fff;
                    outline: none;

                    &--success {
                        border: 1px solid #71E6AB;
                        background: #4AD890;
                    }

                    &--error {
                        border: 1px solid #F578A4;
                        background: #EF5289;
                    }
                }
            }

            &.-success {
                .popup__content {
                    &:before {
                        border-color: #4AD890;
                    }

                    &:after {
                        content: '\2713';
                        color: #4AD890;
                    }
                }
            }

            &.-error {
                .popup__content {
                    &:before {
                        border-color: #EF5289;
                    }

                    &:after {
                        content: '\2717';
                        color: #EF5289;
                    }
                }
            }

            /**
            * Popup animations.
            * Based on Sweet Alert: "https://t4t5.github.io/sweetalert/"
            */
            @keyframes show-popup {
                0% {
                    transform: translate(-50%, -50%) scale(.7);
                    opacity: 0;
                }

                45% {
                    transform: translate(-50%, -50%) scale(1.05);

                    opacity: 1;
                }

                80% {
                    transform: translate(-50%, -50%) scale(.95);
                }

                100% {
                    transform: translate(-50%, -50%) scale(1);
                }
            }

            @keyframes hide-popup {
                0% {
                    transform: translate(-50%, -50%) scale(1);
                    opacity: 1;
                }

                100% {
                    transform: translate(-50%, -50%) scale(.5);
                    opacity: 0;
                }
            }

            /**
            * Icon animations.
            */
            @keyframes show-icon {
                0% {
                    transform: translateX(-50%) scale(0);
                    opacity: 0;
                }

                100% {
                    transform: translateX(-50%) scale(1);
                    opacity: 1;
                }
            }

            @keyframes show-icon-cirlce {
                0% {
                    transform: translateX(-50%) scale(1, 0);
                    opacity: 0;
                }

                100% {
                    transform: translateX(-50%) scale(1, 1);
                    opacity: 1;
                }
            }
        }

        .container {
            .text-icon {
                display: flex;
                justify-content: center;
                flex-direction: column;

                img {
                    width: 84px;
                    margin: 0 auto;
                    margin-bottom: 50px;
                }

                h1 {
                    padding: 0 100px;

                    @include md {
                        padding: 0;
                    }
                }
            }

            .form-container {
                max-width: 600px;
                margin: 60px auto;

                .email-name {
                    display: flex;
                    justify-content: space-between;
                    flex-basis: 50%;

                    @include md {
                        flex-basis: 100%;
                        flex-direction: column;
                    }

                    .field {
                        width: 50%;
                        margin-bottom: 30px;

                        @include md {
                            width: 100%;
                        }

                        label {
                            font-size: 1.2rem;
                            color: rgba(41, 51, 71, 0.65);
                        }

                        .control {
                            input {
                                width: 100%;
                                font-size: 1.4rem;
                                border: 1px solid #E6ECF8;
                                color: #141c3a;
                                box-shadow: none;
                                font-weight: 300;
                                border-radius: 4px;
                                height: 2.5em;
                                padding: 12px;
                            }
                        }

                        &:first-child {
                            margin-right: 25px;
                        }
                    }
                }

                .field {
                    label {
                        font-size: 1.2rem;
                        color: rgba(41, 51, 71, 0.65);
                    }

                    .control {
                        textarea {
                            width: 100%;
                            font-size: 1.4rem;
                            border: 1px solid #E6ECF8;
                            color: #141c3a;
                            box-shadow: none;
                            font-weight: 300;
                            border-radius: 4px;
                            padding: 12px;
                            height: 200px;
                        }

                        .btn-outline-primary {
                            color: $primary-color;
                            border-color: $primary-color;
                            font-size: 20px;
                            font-weight: 500;
                            border-width: 2px;
                            padding: 10px 40px 6px 40px;
                            margin: auto;
                            display: flex;

                            &:focus {
                                outline: none;
                            }

                            &:hover {
                                color: #fff;
                                background-color: #03A696;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include sm {

    .h1,
    h1 {
        font-size: 1.8rem;
    }

    .h2,
    h2 {
        font-size: 1.3rem;
    }
}